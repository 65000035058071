import React from "react";
import certificate1 from "../assets/images/certificate-1-1.png";
import certificate2 from "../assets/images/certificate-1-2.png";
import teamd1 from "../assets/images/profile/neeti.png";

const TeachersDetails = ({ name, description, imageurl }) => {
  return (
    <section className="team-details">
      <div className="container">
        <div className="row justify-content-between">
        <div className="col-lg-6">
            <div className="team-one__single">
              <div className="team-one__image">
                {console.log(imageurl)}
                {console.log(teamd1)}
                <img src={imageurl} alt="" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <a href="/team-details">{name}</a>
                </h2>
                <p className="team-one__designation">Teacher</p>
              </div>
              <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>

                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            
            <div className="team-details__content">
              <h2 className="team-details__title">Read my story</h2>
              <p className="team-details__text">{description}</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default TeachersDetails;
