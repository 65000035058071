import React from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import TeachersDetails from "../../components/TeachersDetails";
import anagha from "../../assets/images/profile/anagha.png";
const data = {
  name: "Ms Anagha Bahulikar",
  description:
    "Anagha holds a master’s degree in Science from The University of Pune (Maharashtra) and has aced distance learning courses conducted by World Intellectual Property Organization (WIPO) with A+ grades. She is a Post Graduate in Scientific & Technical Writing and a diploma holder in Computer Science. Anagha has three research papers published in international journals, to her credit. Her fellowship gave her the opportunity to present a paper at Shailesh J Mehta School of Management (SJMSOM), IIT Bombay and was conferred with the Best Paper Award at that conference. Also, she has rich experience in counseling parents in the field of early childhood education. Anagha is an accredited Professional Trainer from Jolly Learning Ltd. (UK) for Jolly Phonics & Jolly Grammar. She has attended 6 hours workshop of ‘Brain Gym – Hooking Up from Head to Toe’. She has been conducting various classes and workshops, including handwriting improvement programmes, for children, parents as well as teachers in various schools, for more than 8 years. She also works closely with a school for under-privileged children. ",
};

const TeachersDetailsPage = () => {
  return (
    <Layout pageTitle="KWEC | Teachers Details">
      <Navbar />
      <PageHeader title="Our Team" />
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={anagha} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    {data.name}
                  </h2>
                  <p className="team-one__designation">Founder & Teacher Trainer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-details__content">
                <h2 className="team-details__title">Read my story</h2>
                <p className="team-details__text">{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TeachersDetailsPage;
